import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import PrimaryLinkButton from "./PrimaryLinkButton"
import JobIcon from "./JobIcon"

function JobHero({ job }) {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(relativePath: { eq: "mobile/job-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopBackground: file(relativePath: { eq: "job-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const {
    frontmatter: { title, icon, slug, location },
  } = job

  return (
    <div className="bg-white relative transition-all duration-75 overflow-hidden">
      <div className="absolute right-0 bottom-0 top-0 left-0 pointer-events-none flex items-end lg:items-center">
        <div className="w-64 lg:hidden ml-auto">
          <GatsbyImage
            image={data.mobileBackground.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>
        <div className="w-full max-w-screen-2xl mx-auto h-full hidden lg:block">
          <div className="max-w-2xl h-full ml-auto">
            <GatsbyImage
              image={data.desktopBackground.childImageSharp.gatsbyImageData}
              className="max-w-2xl h-full ml-auto"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="container flex">
          <div className="flex py-12 lg:py-16">
            <div className="flex-shrink-0">
              <JobIcon
                name={icon}
                className="w-12 h-12 md:h-16 md:w-16 text-gray-900 block"
              />
            </div>
            <div className="ml-3">
              <h1 className="text-3xl md:text-5xl leading-tight font-medium text-gray-900">
                {title}
              </h1>
              <p className="max-w-3xl text-base leading-snug md:text-2xl font-medium text-gray-700 pt-2">
                {location}
              </p>
              <div className="pt-6">
                <PrimaryLinkButton
                  to={`/jobs/${slug}/#apply`}
                  title="apply now"
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobHero

import React from "react"
import SectionTitle from "./SectionTitle"
import { Formik, Form } from "formik"

import * as Yup from "yup"
import Input from "./Form/Input"
import PrimaryButton from "./PrimaryButton"
import UploadFile from "./Form/UploadFile"
import * as notification from "./Form/notification"
import { uploadFileToFirebase } from "./Form/utils"
import { sendResumeEmail } from "../emails/emails"

const JobApplySchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  email: Yup.string().email().required().label("Email"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  linkedinProfile: Yup.string().url().required().label("LinkedIn Profile"),
  resumeFile: Yup.array()
    .required()
    .min(1, "Resume/Cv is required!")
    .max(1)
    .label("Your Resume/Cv"),
})

const initialValues = {
  name: "",
  email: "",
  phoneNumber: "",
  linkedinProfile: "",
  resumeFile: [],
}

function JobApply({ job }) {
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <div id="apply" className="relative py-8 md:py-12 bg-gray-50">
      <div className="container relative z-10">
        <SectionTitle
          title="Apply Now"
          excerpt={
            <>
              No strong hierarchy, cooperation with global clients, dev-friendly
              processes, processes based on Scrum and Agile methodologies.
            </>
          }
        />
      </div>
      <div className="container">
        <Formik
          initialValues={initialValues}
          validationSchema={JobApplySchema}
          onSubmit={async ({ resumeFile, ...values }, { resetForm }) => {
            try {
              setIsLoading(true)
              const resume = await uploadFileToFirebase(
                resumeFile[0],
                "resume_"
              )
              const newValues = {
                ...values,
                resume: resume,
                job: {
                  title: job.frontmatter.title,
                  slug: job.frontmatter.slug,
                },
              }

              const { data } = await sendResumeEmail(newValues)
              if (data.message) {
                notification.success(data.message)
              }
              setIsLoading(false)
              resetForm()
            } catch (error) {
              notification.error("Something went wrong. Try again!")
            } finally {
              setIsLoading(false)
            }
          }}
        >
          {formikProps => {
            return (
              <Form className="max-w-4xl pt-8 grid grid-cols-1 md:grid-cols-2 gap-x-4">
                <Input
                  name="name"
                  type="text"
                  label="Your Name"
                  error="Name is required"
                />
                <Input name="email" type="email" label="Your Email" />
                <Input
                  name="phoneNumber"
                  type="tel"
                  label="Phone Number"
                  placeholder="06..."
                />
                <Input
                  name="linkedinProfile"
                  type="text"
                  label="LinkedIn Profile"
                  placeholder="https://www.linkedin.com/in/john-doe"
                />
                <UploadFile
                  name="resumeFile"
                  label="Your Resume/Cv"
                  className="md:col-span-2"
                  acceptedFileTypes={[".doc", ".docx", ".pdf"]}
                  helperText="Supported formats: .doc, .pdf"
                />
                <div>
                  <PrimaryButton disabled={isLoading} type="submit">
                    {isLoading ? "Loading..." : "Submit"}
                  </PrimaryButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default JobApply

import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import JobHero from "../components/JobHero"
import JobApply from "../components/JobApply"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        # author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      fileAbsolutePath
      frontmatter {
        title
        slug
        icon
        location
      }
      body
    }
  }
`

function JobTemplate({ data: { mdx: job } }) {
  if (!job) {
    return <p>No job found</p>
  }

  return (
    <>
      <Seo title={job.frontmatter.title} description={job.excerpt} />
      <JobHero job={job} />
      <div className="container pb-16 pt-8">
        <div className="prose max-w-full">
          <MDXRenderer>{job.body}</MDXRenderer>
        </div>
      </div>
      <JobApply job={job} />
    </>
  )
}

export default JobTemplate
